import Cookie from "js-cookie";
import { RegistService } from "@/services";
import fftData from "@/views/regist/util";
import { UploadUrl } from "@/services/config";
import Contract from "@/components/sign/Contract";
import { pca, pcaa } from "area-data"; // v5 or higher
import PubSub from "@/lib/Pub";
export default {
	name: "SignTeacher",
	components: {
		Contract
	},
	data() {
		return {
			showLoading: false,
			pca,
			pcaa,
			showContract: false,
			institutionList: [],
			httpHeader: {
				token: Cookie.get("token")
			},
			UploadUrl,
			formData: {
				name: "",
				institutionId: null,
				licenseImg: "",
				linkPhone: "",
				email: "",
				linkAddress: "",
				graduateMajor: "",
				years: "",
				isteach: "",
				title: "",
				position: "",
				teachCount: null
			},
			rules: {
				name: [{ required: true, message: "必填", trigger: "blur" }],
				licenseImg: [
					{ required: true, message: "请上传企业申请表", trigger: "blur" }
				],
				linkPhone: [
					{ required: true, message: "必填", trigger: "blur" },
					{ pattern: /^1[34578]\d{9}$/, message: "输入正确的手机号格式" }
				],
				email: [
					{ required: true, message: "请输入邮箱", trigger: "blur" },
					{ type: "email", message: "请输入正确的邮箱地址", trigger: "blur" }
				],
				linkAddress: [
					{ required: true, message: "请输入联系地址", trigger: "blur" }
				],
				position: [
					{ required: true, message: "请输入现任职务", trigger: "blur" }
				],
				graduateMajor: [
					{ required: true, message: "请输入现任职务", trigger: "blur" }
				],
				isteach: [{ required: true, message: "请选择", trigger: "blur" }],
				teachCount: [
					{ required: true, message: "请输入可带学员数", trigger: "blur" },
					{ type: "number", message: "必须为数字值", trigger: "blur" }
				],
				years: [
					{
						required: true,
						message: "请输入从事临床研究年限",
						trigger: "blur"
					},
					{ type: "number", message: "必须为数字值" }
				]
			}
		};
	},
	async created() {
		this.getInstitutionList();
		PubSub.subscribe("hideLoginEvent", () => this.getInstitutionList());

		this.showLoading = true;
		let res = await RegistService.getSignInfo(3);
		this.showLoading = false;
		if (res.code === 200) {
			this.formData = fftData(res.data, this.formData);
		} else if (res.code === 401) {
			PubSub.publish("showLoginEvent");
			return;
		} else {
			this.$message("服务器异常，请稍后重试！");
		}
	},
	methods: {
		uploadSuccess(res) {
			if (res.code === 200) {
				let imgPath = res.data.name;
				this.formData.licenseImg = imgPath;
			} else if (res.code === 401) {
				PubSub.publish("showLoginEvent");
			} else {
				this.$message(res.msg);
			}
		},
		getInstitutionItem(id) {
			let list = this.institutionList;
			for (let i = 0; i < list.length; i++) {
				if (list[i].id === id) {
					return list[i];
				}
			}
		},
		onImageRemove() {
			this.formData.licenseImg = "";
		},
		back() {
			this.$router.back();
		},
		print() {
			window.print();
		},
		async getInstitutionList() {
			let data = await RegistService.getInstitutionList();
			if (data.code === 200) {
				let list = data.data;
				this.institutionList = list;
			} else if (data.code === 401) {
				PubSub.publish("showLoginEvent");
				this.$refs["uploader"].clearFile();
			} else {
				this.$message(data.msg);
			}
		},
		nextStep(formName) {
			this.$refs[formName].validate(valid => {
				if (valid) {
					this.showContract = true;
				} else {
					return false;
				}
			});
		},
		async submit() {
			let {
				name,
				institutionId,
				position,
				title,
				linkPhone,
				email,
				linkAddress,
				years,
				graduateMajor,
				isteach,
				teachCount,
				licenseImg
			} = this.formData;

			let data = {
				name,
				institutionId,
				position,
				title,
				linkPhone,
				email,
				linkAddress,
				years: years + "",
				graduateMajor,
				isTeach: isteach,
				teachCount: teachCount + "",
				licenseImg
			};
			this.showLoading = true;
			let res = await RegistService.signTeacher(data);
			this.showLoading = false;
			if (res.code === 200) {
				this.showContract = false;
				this.$msgbox({
					title: "提交成功",
					message:
						"恭喜您，注册成功！平台将会在10个工作日内，将审核结果发送到联系人邮箱，请注意查收！",
					callback: () => {
						this.$router.push("/");
					},
					showConfirmButton: false,
					type: "success"
				});
			} else if (data.code === 401) {
				PubSub.publish("showLoginEvent");
			} else {
				this.$message("服务器异常，请稍后重试");
			}
		},
		downloadFile() {
			let {
				name,
				institutionId,
				position,
				title,
				linkPhone,
				email,
				linkAddress,
				years,
				graduateMajor,
				isteach,
				teachCount
			} = this.formData;

			let data = {
				name,
				institutionName: this._getInstitutionItem(institutionId).name,
				position,
				title,
				linkPhone,
				email,
				linkAddress,
				years: years + "",
				graduateMajor,
				isTeach: isteach,
				teachCount: teachCount + ""
			};

			RegistService.downloadFile(3, data);
		}
	}
};
